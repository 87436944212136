<template>
	<div class="page flex-col">
		<div class="box_1 align-center flex-col" :class="startX<=600?'wid600':''">
			<div class="" v-if="startX<=600">
				<head-top :type="6" :show="1" :startX="startX"></head-top>
				<div class="image1" @click.stop="toIndex(dataMain.banner.picture[0])">
					<img v-if="dataMain.banner.audio_type==0" :src="dataMain.banner.picture[0].abs_url" alt=""
						style="width: 100%;height:100%">
					<video v-else style="width: 100%;height:100%" :src="dataMain.banner.picture[0].abs_url"></video>
				</div>
				<!-- <el-carousel height="100%" class="image1">
					<el-carousel-item v-for="item in dataMain.banner.picture" :key="item.id" @click.stop="toIndex(item)">
						<img v-if="dataMain.banner.audio_type==0" :src="item.abs_url" alt=""
							style="width: 100%;height:100%">
						<video v-else style="width: 100%;height:100%" :src="item.abs_url"></video>
					</el-carousel-item>
				</el-carousel> -->
				<!-- <img class="image1"
					src="./assets/img/psqkija920q11x9lytxcbqo584at6d5qr750987d6-1fc8-4041-b0be-590d306e44a6.png" alt=""> -->
			</div>
			<div class="box_2 flex-col" v-else>
				<head-top :type="6" :show="1" :startX="startX"></head-top>
				<div class="section1" @click.stop="toIndex(dataMain.banner.picture[0])">
					<img v-if="dataMain.banner.audio_type==0" :src="dataMain.banner.picture[0].abs_url" alt=""
						style="width: 100%;height:100%">
					<video v-else style="width: 100%;height:100%" :src="dataMain.banner.picture[0].abs_url"></video>
				</div>
				<!-- <el-carousel height="100%" class="section1">
					<el-carousel-item v-for="item in dataMain.banner.picture" :key="item.id" @click.stop="toIndex(item)">
						<img v-if="dataMain.banner.audio_type==0" :src="item.abs_url" alt=""
							style="width: 100%;height:100%">
						<video v-else style="width: 100%;height:100%" :src="item.abs_url"></video>
					</el-carousel-item>
				</el-carousel> -->
			</div>
			<div class="box_3 flex-col">
				<div class="text-wrapper_1" id="guanxiT">
					<span class="text_10">I</span>
					<span class="text_11">nvestor</span>
					<span class="text_12">R</span>
					<span class="text_13">elations</span>
				</div>
				<span class="text_14" id="guanxiN">投资者关系</span>
				<div class="blockFlex">
					<div class="block_13 flex-col justify-end bot47" :id="'guanxi'+index" @mouseenter="showIn1 = index"
						@mouseleave="showChild = -1" v-for="(item,index) in dataMain.partner" :key="index">
						<img class="block13" :src="item.cover" alt="">
						<div class="flex-col" style="z-index: 9;">
							<span class="text_52">{{item.name}}</span>
							<span class="text_53">{{item.position}}</span>
							<div class="text-wrapper_15 flex-col"
								:style="showIn1 == index&&startX>600?'height:auto;':'height: 0;'">
								<span class="paragraph_1">
									{{item.intro}}
								</span>
							</div>
						</div>
						<div class="blockBg" v-if="showIn1 == index"></div>
					</div>
					<!-- <div class="block_11 flex-col bot47" v-if="startX>600">
						<div class="text-wrapper_13 flex-col">
							<span class="text_48">Rafeeda&nbsp;EI&nbsp;Nouri</span>
							<span class="text_49">丽捷集团公司总经理</span>
						</div>
					</div> -->
				</div>
			</div>
		</div>
		<div class="" v-if="startX>600">
			<body-footer></body-footer>
		</div>
		<div class="" v-else>
			<body-footer1></body-footer1>
		</div>
	</div>
</template>
<script>
	import headTop from "../../components/headTop.vue"
	import bodyFooter from "../../components/bodyFooter.vue";
	import bodyFooter1 from "../../components/bodyFooter1.vue";
	import {
		partner
	} from "../../util/api";
	export default {
		data() {
			return {
				constants: {},
				startX: 1200,
				dataMain: '',
				showIn1: -1
			};
		},
		components: {
			headTop,
			bodyFooter,
			bodyFooter1
		},
		mounted() {
			this.getDate()
			this.startX = document.body.clientWidth;
			window.onresize = () => {
				return (() => {
					this.startX = document.body.clientWidth;
				})();
			};
			window.addEventListener("scroll", this.scrollHand, true);
		},
		deforeDestroy() {
			window.removeEventListener('scroll', this.scrollHand, true);
		},
		watch: {
			startX(val, oldval) {
				if (val != oldval) {
					// console.log(this.startX, "屏幕宽度");
				}
			},
			lang(val, oldval) {
				if (val != oldval) {
					this.getDate()
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
		},
		computed: {
			lang() {
				return this.$store.getters.getLang;
			},
		},
		methods: {
			getDate() {
				partner({
					lang: this.lang
				}).then((res) => {
					this.dataMain = res.data
					// this.title = res.data.header_nav;
				});
			},
			toIndex(v) {
				if (v.target_rule == 1) {
					this.$router.push("/");
				} else if (v.target_rule == 2) {
					this.$router.push("/aboutUs");
				} else if (v.target_rule == 3) {
					let id = v.param
					this.$router.push("/news/" + id);
				} else if (v.target_rule == 4) {
					let news_id = v.param
					this.$router.push("/newDetail/" + news_id);
				} else if (v.target_rule == 5) {
					let id = v.param
					console.log(id);
					this.$router.push("/product/" + id);
				} else if (v.target_rule == 6) {
					this.$router.push("/companies");
				} else if (v.target_rule == 7) {
					this.$router.push("/Investor");
				} else if (v.target_rule == 8) {
					this.$router.push("/invite");
				} else if (v.target_rule == 9) {
					this.$router.push("/contact");
				}
			},
			scrollHand() {
				if (this.startX > 600) {
					let guanxiT = this.isInViewPortOfOne(document.getElementById('guanxiT'))
					if (guanxiT) {
						document.getElementById('guanxiT').style.fontSize = '72px'
						document.getElementById('guanxiT').style.opacity = '1'
						document.getElementById('guanxiN').style.fontSize = '40px'
						document.getElementById('guanxiN').style.opacity = '1'
					} else {
						document.getElementById('guanxiT').style.fontSize = '2px'
						document.getElementById('guanxiT').style.opacity = '0'
						document.getElementById('guanxiN').style.fontSize = '2px'
						document.getElementById('guanxiN').style.opacity = '0'
					}

					for (let i = 0; i < this.dataMain.partner.length; i++) {
						let id = 'guanxi' + i
						let guanxiL = this.isInViewPortOfOne(document.getElementById(id))
						if (guanxiL) {
							setTimeout(() => {
								document.getElementById(id).style.opacity = '1'
							}, i * 300 + 300)
						} else {
							document.getElementById(id).style.opacity = '0'
						}
					}
				}
			},
			isInViewPortOfOne(el) {
				// viewPortHeight 兼容所有浏览器写法
				const offset = el.getBoundingClientRect();
				const offsetTop = offset.top;
				const offsetBottom = offset.bottom;
				if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
					// console.log('进入可视区域');
					return true
				} else {
					return false
					// console.log('移出可视区域');
				}
			},
		}
	};
</script>
<style scoped lang="css" src="./assets/index.css" />
<style lang="scss">
	.wid600 {
		width: 100%;

		.box_3 {
			width: 100%;

			.text-wrapper_1 {
				font-size: 8.667rem;
				line-height: 5.333rem;
				text-transform: uppercase;
				margin: 0;
				margin-top: 13.333rem;
			}

			.text_14 {
				height: auto;
				font-size: 5rem;
				line-height: 5rem;
				text-transform: uppercase;
				margin: 0 auto;
				margin-top: 4rem;
			}

			.blockFlex {
				margin-top: 10rem;
				width: 97%;
				margin-bottom: 5rem;

				.bot47 {
					margin-bottom: 3rem;
				}

				.block_11 {
					width: 57rem;
					height: 60.667rem;

					// height: auto;
					.text-wrapper_13 {
						.text_48 {
							width: auto;
							height: auto;
							margin: 0;
							font-size: 3rem;
							line-height: 3rem;
							margin-left: 3.667rem;
						}

						.text_49 {
							width: auto;
							height: auto;
							margin: 2rem 0 6rem 3.667rem;
							font-size: 2rem;
							line-height: 2rem;
						}
					}
				}

				.block_12 {
					width: 57rem;
					height: 60.667rem;

					// height: auto;
					.text-wrapper_13 {
						.text_48 {
							width: auto;
							height: auto;
							margin: 0;
							font-size: 3rem;
							line-height: 3rem;
							margin-left: 3.667rem;
						}

						.text_49 {
							width: auto;
							height: auto;
							margin: 2rem 0 6rem 3.667rem;
							font-size: 2rem;
							line-height: 2rem;
						}
					}
				}

				.block_13 {
					width: 57rem;
					height: 60.667rem;

					// height: auto;
					.text_52 {
						width: auto;
						height: auto;
						margin: 0;
						font-size: 3rem;
						line-height: 3rem;
						margin-left: 3.667rem;
					}

					.text_53 {
						width: auto;
						height: auto;
						margin: 2rem 0 2rem 3.667rem;
						font-size: 2rem;
						line-height: 2rem;
					}

					.text-wrapper_15 {
						width: 100%;
						height: auto;

						.paragraph_1 {
							width: 85%;
							height: auto;
							margin: 0.7rem 0 5rem 3.667rem;
							font-size: 1.667rem;
							line-height: 3rem;
						}
					}
				}

				.block_14 {
					width: 57rem;
					height: 60.667rem;

					// height: auto;
					.text-wrapper_13 {
						.text_48 {
							width: auto;
							height: auto;
							margin: 0;
							font-size: 3rem;
							line-height: 3rem;
							margin-left: 3.667rem;
						}

						.text_49 {
							width: auto;
							height: auto;
							margin: 2rem 0 6rem 3.667rem;
							font-size: 2rem;
							line-height: 2rem;
						}
					}
				}
			}
		}
	}
</style>